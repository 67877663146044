import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useHistory, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/default-avatar.png"
// actions
import { changePassword, reauthenticate } from "helpers/firebase"

const UserProfile = props => {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [password, setPassword] = useState("")
  const history = useHistory()

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setname(obj.firstname + " " + obj.lastname)
      setemail(obj.email)
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      await reauthenticate(currentPassword)

      await changePassword(password)
      setSuccess("Passwort erfolgreich geändert!")
      setCurrentPassword("")
      setPassword("")
      setTimeout(() => {
        setSuccess("")
      }, 3000)
    } catch (error) {
      console.log(error)
      console.log(error.code)

      const errorCode = error.code

      if (errorCode === "auth/wrong-password")
        setError("Aktuelles Passwort ist falsch!")
      else if (errorCode === "auth/weak-password")
        setError("Das Passwort muss mind. 6 Zeichen beinhalten!")
      else setError("Es ist ein unerwarteter Fehler aufgetreten!")

      setTimeout(() => {
        setError()
      }, 3000)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profil | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Profil" breadcrumbItem="Mein Profil" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3 me-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <h4 className="card-title mb-4">Passwort ändern</h4>

              <Card>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <Label className="form-label">Aktuelles Passwort</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Aktuelles Passwort eingeben"
                        type="password"
                        value={currentPassword}
                        onChange={e => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <Label className="form-label">Passwort</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Neues Passwort eingeben"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="text-center mt-4">
                      <Button type="submit" color="danger">
                        Passwort ändern
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
