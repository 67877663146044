import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useParams, withRouter } from "react-router-dom"
import { map, size } from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"

// import charts
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import instructionsColumns from "./instructionsColumns"
import {
  getInstructionsForUser,
  getUser,
  sendRemindersForUser,
} from "helpers/firebase"
import Loading from "components/Common/Loading"
import certificationsColumns from "./certificationsColumns"
import { async } from "@firebase/util"

const EmployeeAdminDetails = props => {
  //   const userProfile = JSON.parse(localStorage.getItem("authUser"))
  const [userProfile, setUserProfile] = useState({})
  const [openInstructions, setOpenInstructions] = useState([])
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState()
  const [miniCards, setMiniCards] = useState({})
  const history = useHistory()
  let { companyID, userID } = useParams()

  useEffect(async () => {
    const user = await getUser(userID, companyID)
    if (!user) history.push("/dashboard")

    setUserProfile(user)
    setOpenInstructions(await getInstructionsForUser(userID))
    setLoading(false)
  }, [])

  useEffect(() => {
    setMiniCards([
      {
        title: "Unterw. Gesamt",
        iconClass: "bx-copy-alt",
        text:
          "" +
          ((userProfile?.certifications?.length ?? 0) +
            userProfile.instructions?.length ?? 0),
      },
      {
        title: "Unterw. Offen",
        iconClass: "bx-hourglass",
        text: "" + userProfile.instructions?.length,
      },
      {
        title: "Gültige Zertifikate",
        iconClass: "bx-award",
        text: "" + (userProfile.certifications?.length ?? 0),
      },
    ])
  }, [openInstructions])

  const sendReminder = async () => {
    setLoading(true)
    await sendRemindersForUser(userID)
    setSuccess("Erinnerung erfolgreich gesendet!")
    setTimeout(() => {
      setSuccess()
    }, 3000)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Loading isLoading={loading}>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Account" breadcrumbItem="Dashboard" />

            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Persönliche Informationen
                    </CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Name :</th>
                            <td>
                              {userProfile.firstname} {userProfile.lastname}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Personalnr. :</th>
                            <td>{userProfile.empNr}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>
                              <a href={"mailto:" + userProfile.email}>
                                {userProfile.email}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Abteilungen :</th>
                            <td>
                              {map(
                                userProfile.departments,
                                (department, index) =>
                                  index < 3 && (
                                    <Link
                                      to="#"
                                      className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                                      key={"_skill_" + userProfile.id + index}
                                    >
                                      {department}
                                    </Link>
                                  )
                              )}
                              {size(userProfile.departments) > 3 && (
                                <Link
                                  to="#"
                                  className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                                  key={"_skill_" + userProfile.id}
                                >
                                  + {size(userProfile.departments) - 3} weitere
                                </Link>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td colSpan={2}>
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() => {
                                  sendReminder()
                                }}
                              >
                                <i className="bx bx-mail-send font-size-16 align-middle me-2"></i>
                                Erinnerung senden
                              </Button>

                              {success && (
                                <>
                                  <br />
                                  <br />
                                  <span className="success">{success}</span>
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8">
                <Row>
                  {map(miniCards, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Offene Unterweisungen
                    </CardTitle>
                    <ToolkitProvider
                      keyField="id"
                      data={userProfile.instructions || []}
                      columns={instructionsColumns()}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  {...toolkitProps.baseProps}
                                />
                                {!userProfile.instructions ||
                                  (userProfile.instructions?.length == 0 && (
                                    <p style={{ textAlign: "center" }}>
                                      Derzeit alle Unterweisungen abgeschlossen!
                                    </p>
                                  ))}
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Zertifikate</CardTitle>
                  <ToolkitProvider
                    keyField="id"
                    data={userProfile.certifications || []}
                    columns={certificationsColumns()}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                remote
                                bordered={false}
                                striped={false}
                                classes={
                                  "table align-middle table-nowrap table-hover"
                                }
                                {...toolkitProps.baseProps}
                              />
                              {!userProfile.certifications ||
                                (userProfile.certifications?.length == 0 && (
                                  <p style={{ textAlign: "center" }}>
                                    Keine abgeschlossenen Unterweisungen
                                    vorhanden!
                                  </p>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </Loading>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EmployeeAdminDetails)
