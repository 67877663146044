import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Warnings from "./warnings"

//redux
import { getCompany, getUsers } from "helpers/firebase"
import Loading from "components/Common/Loading"
import CardInfos from "./cardInfos"

const DashboardAdmin = props => {
  const [users, setUsers] = useState([])
  const [company, setCompany] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    setUsers(await getUsers())
    setCompany(await getCompany())
    setLoading(false)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Admin Dashboard | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Unterweisungen" breadcrumbItem="Übersicht" />
          <Loading isLoading={loading}>
            <Row>
              <Row>
                <CardInfos users={users} company={company} />
                <Col lg="4">
                  <Warnings users={users} />
                </Col>
              </Row>
            </Row>
          </Loading>
        </Container>
      </div>
    </React.Fragment>
  )
}

DashboardAdmin.propTypes = {
  match: PropTypes.object,
}

export default withRouter(DashboardAdmin)
