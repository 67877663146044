import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import PropTypes from "prop-types"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Import Breadcrumb
import DeleteModal from "components/Common/DeleteModal"
import { Link } from "react-router-dom"
import "./datatables.scss"

const QuestionsTable = ({ instruction, setInstruction }) => {
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  if (!instruction.questions) instruction.questions = []
  const [currentQuestion, setCurrentQuestion] = useState()

  const handleSubmit = e => {
    e.preventDefault()
    setInstruction({
      ...instruction,
      questions: [...instruction.questions, currentQuestion],
    })
    toggle()
  }

  const onClickDelete = question => {
    setCurrentQuestion(question)
    setDeleteModal(true)
  }

  const handleDeleteQuestion = _ => {
    setInstruction({
      ...instruction,
      questions: instruction.questions.filter(
        q => q.text !== currentQuestion.text
      ),
    })
    setDeleteModal(false)
  }

  const columns = [
    {
      dataField: "text",
      text: "Frage",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Aktionen",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, question) => (
        <div className="d-flex gap-3">
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(question)}
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  // Table Data

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: instruction.questions.length, // replace later with size(customers),
    custom: true,
  }

  // const { SearchBar } = Search

  const toggle = () => {
    setCurrentQuestion({
      text: "",
      answers: [
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
      ],
    })
    setModal(!modal)
  }

  return (
    <Col className="col-12">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteQuestion}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="text"
            columns={columns}
            data={instruction.questions ?? []}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="text"
                columns={columns}
                data={instruction.questions ?? []}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-3">
                      <Col md="4" className="d-flex align-items-center">
                        <h4>Fragen</h4>
                      </Col>
                      <Col className="d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center">
                          <div className="search-box me-4 d-inline-block">
                            <div className="position-relative">
                              <Search.SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </div>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary mb-2"
                          onClick={toggle}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Frage hinzufügen
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
      {currentQuestion && (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            Frage hinzufügen
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">Frage</Label>
                    <Input
                      name="question"
                      type="text"
                      value={currentQuestion.text}
                      onChange={e =>
                        setCurrentQuestion({
                          ...currentQuestion,
                          text: e.target.value,
                        })
                      }
                    />
                  </div>

                  {currentQuestion.answers.map((answer, idx) => (
                    <div key={idx} className="mb-3">
                      <div className="d-flex justify-content-between">
                        <Label className="form-label">Antwort {idx + 1}</Label>
                        <Label className="form-label">Richtig/Falsch</Label>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="input-group mb-3 me-4">
                          <Input
                            name="question"
                            type="text"
                            value={currentQuestion.answers[idx].text}
                            onChange={e => {
                              {
                                currentQuestion.answers[idx].text =
                                  e.target.value
                                setCurrentQuestion({
                                  ...currentQuestion,
                                  answers: currentQuestion.answers,
                                })
                              }
                            }}
                          />
                        </div>
                        <div className="orm-check form-switch form-switch-md mb-3 mx-2 ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            onChange={e => {
                              {
                                currentQuestion.answers[idx].isCorrect =
                                  !currentQuestion.answers[idx].isCorrect
                                setCurrentQuestion({
                                  ...currentQuestion,
                                  answers: currentQuestion.answers,
                                })
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user">
                      Speichern
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Col>
  )
}
QuestionsTable.propTypes = {
  instruction: PropTypes.any,
  setInstruction: PropTypes.any,
}
export default QuestionsTable
