import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map, size } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"

//Import Images
import profile1 from "assets/images/profile-img.png"

// import charts
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import instructionsColumns from "./instructionsColumns"
import { getInstructionsForUser } from "helpers/firebase"
import Loading from "components/Common/Loading"

const Dashboard = props => {
  const userProfile = JSON.parse(localStorage.getItem("authUser"))
  const [openInstructions, setOpenInstructions] = useState([])
  const [loading, setLoading] = useState(true)
  const [miniCards, setMiniCards] = useState({})

  useEffect(async () => {
    setOpenInstructions(await getInstructionsForUser())
    setLoading(false)
  }, [])

  useEffect(() => {
    setMiniCards([
      {
        title: "Unterw. Gesamt",
        iconClass: "bx-copy-alt",
        text:
          "" +
          ((userProfile?.certifications?.length ?? 0) +
            (openInstructions?.length ?? 0)),
      },
      {
        title: "Unterw. Offen",
        iconClass: "bx-hourglass",
        text: "" + (openInstructions?.length ?? 0),
      },
      {
        title: "Gültige Zertifikate",
        iconClass: "bx-award",
        text: "" + (userProfile?.certifications?.length ?? 0),
      },
    ])
  }, [openInstructions])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Loading isLoading={loading}>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Account" breadcrumbItem="Dashboard" />

            <Row>
              <Col xl="4">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Willkommen !</h5>
                          <p>Sicher & Unterwiesen</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="12">
                        <div className=" profile-user-wid mb-4">
                          <div className="avatar-md">
                            <span className="avatar-title rounded-circle h2">
                              {userProfile.firstname?.charAt(0)}
                              {userProfile.lastname?.charAt(0)}
                            </span>
                          </div>
                        </div>
                        <h5 className="font-size-15 text-truncate">
                          {userProfile.firstname} {userProfile.lastname}
                        </h5>
                        <p className="text-muted mb-0">
                          {map(
                            userProfile.departments,
                            (department, index) =>
                              index < 2 && (
                                <Link
                                  to="#"
                                  className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                                  key={"_skill_" + userProfile.id + index}
                                >
                                  {department}
                                </Link>
                              )
                          )}
                          {size(userProfile.departments) > 2 && (
                            <Link
                              to="#"
                              className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                              key={"_skill_" + userProfile.id}
                            >
                              + {size(userProfile.departments) - 2} weitere
                            </Link>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Persönliche Informationen
                    </CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Name :</th>
                            <td>
                              {userProfile.firstname} {userProfile.lastname}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Personalnr. :</th>
                            <td>{userProfile.empNr}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>
                              <a href={"mailto:" + userProfile.email}>
                                {userProfile.email}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Abteilungen :</th>
                            <td>
                              {map(
                                userProfile.departments,
                                (department, index) =>
                                  index < 3 && (
                                    <Link
                                      to="#"
                                      className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                                      key={"_skill_" + userProfile.id + index}
                                    >
                                      {department}
                                    </Link>
                                  )
                              )}
                              {size(userProfile.departments) > 3 && (
                                <Link
                                  to="#"
                                  className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                                  key={"_skill_" + userProfile.id}
                                >
                                  + {size(userProfile.departments) - 3} weitere
                                </Link>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8">
                <Row>
                  {map(miniCards, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      Offene Unterweisungen
                    </CardTitle>
                    <ToolkitProvider
                      keyField="id"
                      data={openInstructions || []}
                      columns={instructionsColumns()}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-hover"
                                  }
                                  {...toolkitProps.baseProps}
                                />
                                {(!openInstructions ||
                                  openInstructions?.length == 0) && (
                                  <p style={{ textAlign: "center" }}>
                                    Derzeit alle Unterweisungen abgeschlossen!
                                  </p>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Loading>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

export default withRouter(Dashboard)
