import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { useReactToPrint } from "react-to-print"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "./datatables.scss"
import { certificationsColumns } from "./CertificationsColumns"
import { getCertifications } from "helpers/firebase"
import Loading from "components/Common/Loading"
import moment from "moment"

const CertificatesTable = () => {
  const [certifications, setCertifications] = useState([])
  const [loading, setLoading] = useState(true)
  const componentRef = useRef()
  const currentUser = JSON.parse(localStorage.getItem("authUser"))
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: certifications?.length, // replace later with size(customers),
    custom: true,
  }

  useEffect(async () => {
    setCertifications((await getCertifications()) ?? [])
    setLoading(false)
  }, [])

  return (
    <Col className="col-12">
      <Card>
        <CardBody>
          <Loading isLoading={loading}>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={certificationsColumns}
              data={certifications}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={certificationsColumns}
                  data={certifications}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Search.SearchBar
                                {...toolkitProps.searchProps}
                                placeholder="Suche"
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col md="8">
                          <div
                            style={{ marginLeft: "auto", width: "fit-content" }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary align-self-end"
                              onClick={handlePrint}
                            >
                              <i className="bx bx-download font-size-16 align-middle me-2"></i>{" "}
                              Download
                            </button>
                          </div>
                        </Col>
                      </Row>

                      <div>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                              {(!certifications ||
                                certifications.length == 0) && (
                                <p className="text-center mt-5 mb-4">
                                  Noch keine abgeschlossenen Unterweisungen!
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                      <div style={{ display: "none" }}>
                        <div ref={componentRef} style={{ padding: "8vh 5vw" }}>
                          <Row>
                            <h1 style={{ marginBottom: "50px" }}>
                              Abgeschlossene Unterweisungen
                            </h1>
                            <p>
                              <b>Name:</b> {currentUser.firstname}{" "}
                              {currentUser.lastname}
                            </p>
                            <p>
                              <b>Email:</b> {currentUser.email}
                            </p>
                            <p>
                              <b>Erstellungsdatum:</b>{" "}
                              {moment(new Date()).format("DD.MMM.Y")}
                            </p>
                            <Col xl="12" style={{ marginTop: "40px" }}>
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Loading>
        </CardBody>
      </Card>
    </Col>
  )
}

export default CertificatesTable
