import React from "react"
import { Link } from "react-router-dom"

export const usersColumns = [
  {
    text: "Name",
    dataField: "firstname",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, user) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {user.firstname + " " + user.lastname}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cellContent, user) => (
      <a href={"mailto:" + user.email} className="avatar-xs">
        {user.email}
      </a>
    ),
  },
]
