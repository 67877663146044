import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logoAUVA from "../../assets/images/auva/logo.png"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSmall from "../../assets/images/logo-sm-light.png"
import { isSICHERUU } from "helpers/misc_helper"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          {isSICHERUU() ? (
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img
                  src={logoLightSmall}
                  alt="logo sicher und unterwiesen small"
                  height="30"
                />
              </span>
              <span className="logo-lg">
                <img
                  className="mt-2"
                  src={logoLightPng}
                  alt="logo sicher und unterwiesen"
                  height="60"
                />
              </span>
            </Link>
          ) : (
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoAUVA} alt="logo auva" height="20" />
              </span>
              <span className="logo-lg">
                <img
                  className="mt-2"
                  src={logoAUVA}
                  alt="logo auva"
                  height="70"
                />
              </span>
            </Link>
          )}
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default withRouter(withTranslation()(Sidebar))
