import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { collection, doc, getDoc, getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCZigef7hMXZHKAocFHn-AsUOmr3ljldpU",
  authDomain: "sicherundunterwiesen-fc769.firebaseapp.com",
  projectId: "sicherundunterwiesen-fc769",
  storageBucket: "sicherundunterwiesen-fc769.appspot.com",
  messagingSenderId: "241918268984",
  appId: "1:241918268984:web:85d7ae5ab6eaa322b978cd",
  measurementId: "G-CJCWV16N9P",
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)

export const auth = getAuth(app)

auth.onAuthStateChanged(async user => {
  if (user) {
    const userFromDB = (await getDoc(doc(db, "users", user.uid))).data()
    localStorage.setItem(
      "authUser",
      JSON.stringify({ ...userFromDB, uid: user.uid })
    )
  } else {
    localStorage.removeItem("authUser")
  }
})
