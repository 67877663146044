import React, { useEffect, useState } from "react"
import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"
import { getMyDepartments } from "helpers/firebase"
import PropTypes from "prop-types"

const RightBar = () => {
  const [departments, setDepartments] = useState([])

  useEffect(async () => {
    setDepartments(await getMyDepartments())
  }, [])

  return (
    <React.Fragment>
      <Col xl={3} lg={4}>
        <Card>
          <CardBody className="p-4">
            <div className="search-box">
              <p className="text-muted">Suche</p>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control rounded bg-light border-light"
                  placeholder="Suchtext..."
                />
                <i className="mdi mdi-magnify search-icon"></i>
              </div>
            </div>

            <hr className="my-4" />

            <div>
              <p className="text-muted">Meine Abteilungen</p>

              <ul className="list-unstyled fw-medium">
                {departments?.map((dep, index) => {
                  return (
                    <li key={index}>
                      <i className="mdi mdi-chevron-right me-1"></i> {dep}
                    </li>
                  )
                })}
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default RightBar
