import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import QuestionsTable from "./questionsTable"
import Loading from "components/Common/Loading"
import {
  addInstruction,
  getInstructionSuperAdmin,
  updateInstruction,
  uploadFile,
} from "helpers/firebase"

const Instruction = () => {
  const { id } = useParams()
  const [instruction, setInstruction] = useState({})
  const [selectedFiles, setselectedFiles] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    if (id) setInstruction(await getInstructionSuperAdmin(id))

    setLoading(false)
  }, [])

  async function handleAcceptedFiles(files) {
    setLoading(true)
    try {
      const url = await uploadFile(files[0])

      setInstruction({ ...instruction, videoURL: url })

      setselectedFiles(files)
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  function handleSubmit() {
    setLoading(true)
    if (instruction.id) updateInstruction(instruction)
    else addInstruction(instruction)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Unterweisung Neu | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Loading isLoading={loading}>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Unterweisungen" breadcrumbItem="Neu" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Neue Unterweisung</CardTitle>
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="instructionname"
                          className="col-form-label col-lg-2"
                        >
                          Name
                        </Label>
                        <Col lg="10">
                          <Input
                            id="instructionname"
                            name="instructionname"
                            type="text"
                            className="form-control"
                            placeholder="Name..."
                            value={instruction.name}
                            onChange={e =>
                              setInstruction({
                                ...instruction,
                                name: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        Unterweisung
                      </Label>
                      <Col lg="10">
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Video hochladen</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <div className="my-5">
                        <video
                          id="video"
                          width="600"
                          height="400"
                          controls
                          className="mx-auto d-block"
                          style={{
                            maxWidth: "100%",
                            height: "fit-content",
                          }}
                        >
                          <source
                            src={instruction?.videoURL}
                            type="video/mp4"
                          />
                          Ihr Browser unterstüzt Videos nicht.
                        </video>
                      </div>
                    </Row>
                    <FormGroup className="mb-4" row>
                      <Col lg="12">
                        <QuestionsTable
                          instruction={instruction}
                          setInstruction={setInstruction}
                        />
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <div className="text-sm-end">
                        <Link to="/unterweisungen-superadmin">
                          <Button type="button" color="danger mx-2">
                            Abbrechen
                          </Button>
                        </Link>
                        <Button
                          type="submit"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Speichern
                        </Button>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Loading>
      </div>
    </React.Fragment>
  )
}

export default Instruction
