import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-sm-light.png"
import { login as LoginFirebase } from "helpers/firebase"
import Loading from "components/Common/Loading"

const Login = props => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const previousPath = props.location?.state?.from?.pathname

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte Email eingeben"),
      password: Yup.string().required("Bitte Passwort eingeben"),
    }),
    onSubmit: async values => {
      setLoading(true)
      LoginFirebase(
        values.email,
        values.password,
        setLoading,
        setError,
        history,
        previousPath
      )
    },
  })

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Sicher & Unterwisen LMS</title>
      </MetaTags>
      <Loading isLoading={loading}>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Wilkommen !</h5>
                          <p className="text-muted">Sicher & Unterwiesen</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <div className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-dark">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="40"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Email eingeben"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Passwort</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Passwort eingeben"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Passwort vergessen
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} Sicher & Unterwiesen</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Loading>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
