import { auth, db } from "helpers/firebase-config"
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore"
import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL

const api_config = {
  headers: {
    API_KEY: process.env.REACT_APP_BACKEND_API_KEY,
  },
}

export const login = async (
  email,
  password,
  setLoading,
  setError,
  history,
  previousPath
) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password)
    const userFromDB = (await getDoc(doc(db, "users", user.user.uid))).data()
    localStorage.setItem(
      "authUser",
      JSON.stringify({ ...userFromDB, uid: user.uid })
    )
    history.push(previousPath ?? "/dashboard")
  } catch (error) {
    console.log(error.code)
    if (
      error.code === "auth/wrong-password" ||
      error.code === "auth/user-not-found"
    )
      displayMessage(setError, "Email oder Passwort falsch")
    else displayMessage(setError, "Es ist ein unerwarteter Fehler aufgetreten")
    setLoading(false)
  }
}

export const resetPassword = async email => {
  await sendPasswordResetEmail(auth, email)
}

export const changePassword = async password => {
  await updatePassword(auth.currentUser, password)
}

export const reauthenticate = async password => {
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  )
  await reauthenticateWithCredential(auth.currentUser, credential)
    .then(() => {
      return
    })
    .catch(error => {
      throw error
    })
}

export const getUsers = async companyID => {
  try {
    let id = companyID
    if (!id) id = (await getAuthenticatedUser()).company.id
    const q = query(collection(db, "users"), where("company.id", "==", id))
    const employees = await getDocs(q)
    const company = (await getDoc(doc(db, "companies", id))).data()

    return employees.docs.map(doc => {
      const user = doc.data()

      const allInstructions = company.instructions?.filter(i => {
        return (
          i.departments?.filter(d => {
            return user.departments?.indexOf(d) != -1
          }).length > 0
        )
      })

      const doneInstructions =
        user.certifications
          ?.filter(
            c =>
              c.completionDate.toDate().getFullYear() ===
              new Date().getFullYear()
          )
          .map(c => c.id) ?? []

      return {
        ...user,
        id: doc.id,
        instructions:
          allInstructions?.filter(i => !doneInstructions.includes(i.id)) ?? [],
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const getUser = async (email, companyID) => {
  try {
    const q = query(
      collection(db, "users"),
      where("email", "==", email),
      where(
        "company.id",
        "==",
        companyID ?? JSON.parse(localStorage.getItem("authUser")).company.id
      )
    )
    const currentUser = (await getDocs(q)).docs[0]?.data()

    if (!currentUser) return null

    const company = (
      await getDoc(doc(db, "companies", currentUser.company.id))
    ).data()

    const allInstructions = company.instructions?.filter(i => {
      return (
        i.departments?.filter(d => {
          return currentUser.departments?.indexOf(d) != -1
        }).length > 0
      )
    })

    const doneInstructions =
      currentUser.certifications
        ?.filter(
          c =>
            c.completionDate.toDate().getFullYear() === new Date().getFullYear()
        )
        .map(c => c.id) ?? []

    return {
      ...currentUser,
      instructions:
        allInstructions?.filter(i => !doneInstructions.includes(i.id)) ?? [],
      certifications: currentUser.certifications ?? [],
    }
  } catch (error) {
    console.log(error)
  }
}

export const getCompany = async () => {
  const currentUser = await getAuthenticatedUser()

  let company = await getDoc(doc(db, "companies", currentUser.company.id))

  return company.data()
}

export const createUser = async (values, setError, companyID) => {
  try {
    const pwd = generatePassword()

    const res = await axios.post(
      `${API_BASE_URL}`,
      {
        email: values.email,
        password: pwd,
      },
      api_config
    )

    console.log(values.email, pwd)

    let company
    if (companyID) {
      const document = await getDoc(doc(db, "companies", companyID))
      company = { ...document.data(), id: document.id }
    }

    delete values.instructions

    const currentUser = await getAuthenticatedUser()
    const newUser = {
      ...values,
      company: company ?? currentUser.company,
      createdAt: new Date(),
      id: res.data.uid,
    }

    await setDoc(doc(db, "users", res.data.uid), newUser)
  } catch (error) {
    if (error?.response?.data?.code === "auth/email-already-exists")
      displayMessage(
        setError,
        "Diese Email wird bereits von einem Mitarbeiter verwendet!"
      )
    else
      displayMessage(
        setError,
        "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      )

    console.log(error)
    throw error
  }
}

export const updateUser = async (values, setError) => {
  try {
    if (auth.currentUser.email != values.email)
      await axios.put(
        `${API_BASE_URL}`,
        {
          id: values.id,
          email: values.email,
        },
        api_config
      )

    delete values.instructions

    await updateDoc(doc(db, "users", values.id), values)
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.code === "auth/email-already-exists")
      displayMessage(
        setError,
        "Diese Email wird bereits von einem Mitarbeiter verwendet!"
      )
    else if (error?.response?.data?.code === "auth/invalid-email")
      displayMessage(setError, "Diese Email hat kein gütliges Format!")
    else
      displayMessage(
        setError,
        "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      )
    throw error
  }
}

export const deleteUser = async (id, setError) => {
  try {
    const user = (await getDoc(doc(db, "users", id))).data()
    user.deletedAt = new Date()

    await axios.delete(`${API_BASE_URL}/${id}`, api_config)
    await deleteDoc(doc(db, "users", id))

    await updateDoc(doc(db, "companies", user.company.id), {
      deletedUsers: arrayUnion(user),
    })
  } catch (error) {
    displayMessage(
      setError,
      "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut."
    )
  }
}

export const getDepartments = async companyID => {
  try {
    const q = query(
      collection(db, "users"),
      where(
        "company.id",
        "==",
        companyID ?? (await getAuthenticatedUser()).company.id
      )
    )
    const employees = await getDocs(q)

    let deps = []
    employees.docs.forEach(doc => {
      deps = deps.concat(doc.data().departments ?? [])
    })

    const grouped = []

    deps.forEach(e => {
      var foundElemenet = grouped.find(g => g.name === e)
      if (foundElemenet) foundElemenet.numOfPersons++
      else grouped.push({ name: e, numOfPersons: 1 })
    })

    return grouped ?? []
  } catch (error) {
    console.log(error)
  }
}

export const getUsersFromDepartment = async (department, companyID) => {
  try {
    let currentUser
    if (!companyID) currentUser = await getAuthenticatedUser()

    const q = query(
      collection(db, "users"),
      where("company.id", "==", companyID ?? currentUser.company.id),
      where("departments", "array-contains", department)
    )
    const employees = await getDocs(q)
    return employees.docs.map(doc => doc.id)
  } catch (error) {
    console.log(error)
  }
}

export const addDepartment = async (
  department,
  users,
  selectedUsers,
  initDepartment,
  companyID
) => {
  try {
    const addedDepartments = []

    await users.forEach(async user => {
      let changed = false
      if (
        !user.departments?.includes(department) &&
        selectedUsers.includes(user.id)
      ) {
        if (!user.departments) user.departments = []
        user.departments.push(department)
        changed = true
        if (!addedDepartments.includes(department))
          addedDepartments.push(department)
      } else if (
        user.departments?.includes(department) &&
        !selectedUsers.includes(user.id)
      ) {
        user.departments = user.departments?.filter(dep => dep != department)
        changed = true
      }

      if (
        initDepartment &&
        initDepartment != department &&
        user.departments?.includes(initDepartment)
      ) {
        user.departments = user.departments?.filter(
          dep => dep != initDepartment
        )
        changed = true
      }

      if (changed) {
        await updateDoc(
          doc(db, "users", user.id),
          "departments",
          user.departments
        )
      }
    })
    await checkDepartments(department, initDepartment, companyID)
  } catch (error) {
    console.log(error)
  }
}

const checkDepartments = async (department, initDepartment, companyID) => {
  let company = await getDoc(doc(db, "companies", companyID))

  company = company.data()

  company.instructions
    .filter(i => i.name.startsWith("Allgemein Teil"))
    .forEach(i => {
      if (!i.departments) i.departments = []
      if (!i.departments?.includes(department)) i.departments?.push(department)
    })

  company.instructions.forEach(i => {
    const idx = i.departments?.indexOf(initDepartment)
    if (idx !== -1) i.departments?.splice(idx, 1)
  })

  await updateDoc(
    doc(db, "companies", companyID),
    "instructions",
    company.instructions
  )
}

export const sendReminders = async (department, companyID) => {
  if (!companyID) companyID = (await getAuthenticatedUser()).company.id

  await axios.post(
    `${API_BASE_URL}/sendRemindersForCompany`,
    {
      department,
      companyID,
    },
    api_config
  )
}

export const sendRemindersForUser = async email => {
  await axios.post(
    `${API_BASE_URL}/sendRemindersForUser`,
    {
      email,
    },
    api_config
  )
}

export const deleteDepartment = async (department, users, companyID) => {
  try {
    let currentUser
    if (!companyID) currentUser = await getAuthenticatedUser()

    await users.forEach(async user => {
      if (user.departments?.includes(department))
        user.departments = user.departments?.filter(dep => dep != department)

      await updateDoc(
        doc(db, "users", user.id),
        "departments",
        user.departments
      )

      let company = await getDoc(
        doc(db, "companies", companyID ?? currentUser.company.id)
      )

      company = company.data()
      company.instructions.forEach(i => {
        const idx = i.departments?.indexOf(department)
        if (idx !== -1) i.departments?.splice(idx, 1)
      })

      await updateDoc(
        doc(db, "companies", companyID ?? currentUser.company.id),
        "instructions",
        company.instructions
      )
    })
  } catch (error) {
    console.log(error)
  }
}

export const getInstructionsAdmin = async companyID => {
  try {
    let currentUser = {}
    if (!companyID) currentUser = await getAuthenticatedUser()
    const company = await getDoc(
      doc(db, "companies", companyID ?? currentUser.company.id)
    )
    return company
      .data()
      .instructions.sort((a, b) => a.name.localeCompare(b.name))
  } catch (error) {
    console.log(error)
  }
}

export const assignInstruction = async (instructions, companyID) => {
  try {
    let currentUser

    if (!companyID) currentUser = await getAuthenticatedUser()
    await updateDoc(
      doc(db, "companies", companyID ?? currentUser.company.id),
      "instructions",
      instructions
    )
  } catch (error) {
    console.log(error)
  }
}

export const getInstructionsForUser = async _ => {
  try {
    const currentUser = await getAuthenticatedUser()
    const company = await getDoc(doc(db, "companies", currentUser.company.id))

    let allInstructions
    if (currentUser.departments)
      allInstructions = company.data().instructions?.filter(i => {
        return (
          i.departments?.filter(d => {
            return currentUser.departments?.indexOf(d) != -1
          }).length > 0
        )
      })
    else allInstructions = []

    const doneInstructions =
      currentUser.certifications
        ?.filter(
          c =>
            c.completionDate.toDate().getFullYear() === new Date().getFullYear()
        )
        .map(c => c.id) ?? []

    return allInstructions?.filter(i => !doneInstructions.includes(i.id)) ?? []
  } catch (error) {
    console.log(error)
  }
}

export const getInstruction = async id => {
  try {
    const currentUser = await getAuthenticatedUser()
    const company = await getDoc(doc(db, "companies", currentUser.company.id))

    return company.data().instructions?.find(i => {
      return i.id === id
    })
  } catch (error) {
    console.log(error)
  }
}

export const getInstructionSuperAdmin = async id => {
  try {
    const instructionDoc = await getDoc(doc(db, "instructions", id))
    return { ...instructionDoc.data(), id: instructionDoc.id }
  } catch (error) {
    console.log(error)
  }
}

export const completeInstruction = async instruction => {
  try {
    const currentUser = await getAuthenticatedUser()
    instruction.completionDate = new Date()

    updateDoc(doc(db, "users", currentUser.uid), {
      certifications: arrayUnion(instruction),
    })
  } catch (error) {
    console.log(error)
  }
}

export const getCertifications = async _ => {
  try {
    const currentUser = await getAuthenticatedUser()

    return currentUser.certifications ?? []
  } catch (error) {
    console.log(error)
  }
}

export const getMyDepartments = async _ => {
  try {
    const currentUser = await getAuthenticatedUser()

    return currentUser.departments ?? []
  } catch (error) {
    console.log(error)
  }
}

export const getAllInstructions = async _ => {
  try {
    const instructions = []
    const querySnapshot = await getDocs(collection(db, "instructions"))

    querySnapshot?.forEach(doc => {
      //todo search for companies
      instructions.push({ id: doc.id, ...doc.data() })
    })
    instructions.sort((a, b) => a.name.localeCompare(b.name))
    return instructions
  } catch (error) {
    console.log(error)
  }
}

export const uploadFile = async file => {
  const storage = getStorage()
  const storageRef = ref(storage, "unterweisungen/" + file.name)

  const snapshot = await uploadBytes(storageRef, file)

  return await getDownloadURL(snapshot.ref)
}

export const addInstruction = async instruction => {
  await addDoc(collection(db, "instructions"), instruction)
  window.location.href = "/unterweisungen-superadmin"
}

export const updateInstruction = async instruction => {
  try {
    const companies = []
    const querySnapshot = await getDocs(collection(db, "companies"))

    querySnapshot?.forEach(doc => {
      companies.push({ id: doc.id, ...doc.data() })
    })

    for (let i = 0; i < companies.length; i++) {
      if (companies[i].instructions?.find(i => i.id === instruction.id)) {
        const foundInstruction = companies[i].instructions.find(
          i => i.id == instruction.id
        )
        foundInstruction.name = instruction.name
        foundInstruction.questions = instruction.questions
        foundInstruction.videoURL = instruction.videoURL

        const companyRef = doc(db, "companies", companies[i].id)
        await updateDoc(companyRef, {
          instructions: companies[i].instructions,
        })
      }
    }

    await updateDoc(doc(db, "instructions", instruction.id), instruction)
    window.location.href = "/unterweisungen-superadmin"
  } catch (error) {
    console.log(error)

    displayMessage(
      setError,
      "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut."
    )
    throw error
  }
}

export const getCompanies = async _ => {
  const data = await getDocs(collection(db, "companies"))
  return await Promise.all(
    data.docs.map(async doc => {
      const q = query(
        collection(db, "users"),
        where("company.id", "==", doc.id)
      )
      const employees = await getDocs(q)
      const numEmps = employees.size
      return { ...doc.data(), id: doc.id, activeLicenses: numEmps }
    })
  )
}

export const addCompany = async newCompany => {
  const q = query(
    collection(db, "instructions"),
    where("name", ">=", "Allgemein Teil"),
    where("name", "<", "Allgemein Teim")
  )
  const querySnapshot = await getDocs(q)

  const instructions = []

  const dueDate = new Date()
  dueDate.setDate(dueDate.getDate() + 14)

  querySnapshot?.forEach(doc => {
    instructions.push({
      id: doc.id,
      ...doc.data(),
      dueDate: Timestamp.fromDate(dueDate),
    })
  })

  newCompany.instructions = instructions

  await addDoc(collection(db, "companies"), newCompany)
}

export const updateCompany = async company => {
  let id = company.id
  if (!id) id = (await getAuthenticatedUser()).company.id
  const q = query(collection(db, "users"), where("company.id", "==", id))
  const employees = await getDocs(q)

  for (let i = 0; i < employees.size; i++) {
    let document = employees.docs[i].data()

    updateDoc(doc(db, "users", employees.docs[i].id), { ...document, company })
  }

  const companyDoc = doc(db, "companies", company.id)
  await updateDoc(companyDoc, company)
}

export const deleteCompany = async id => {
  const companyDoc = doc(db, "companies", id)

  const q = query(collection(db, "users"), where("company.id", "==", id))
  const employees = await getDocs(q)
  const ids = employees.docs.map(doc => doc.id)

  for (let i = 0; i < ids.length; i++) await deleteUser(ids[i])

  await deleteDoc(companyDoc)
}

export const addInstructionToCompany = async (companyID, instructions) => {
  var companyref = doc(db, "companies", companyID)

  const company = (await getDoc(companyref)).data()

  const toAdd = instructions.filter(i => {
    return company.instructions?.find(inst => inst.id === i.id) === undefined
  })

  if (company.instructions)
    await updateDoc(companyref, {
      instructions:
        company.instructions?.filter(
          inst => instructions.filter(i => i.id === inst.id).length > 0
        ) ?? [],
    })

  for (let i = 0; i < toAdd.length; i++) {
    const instruction = await getDoc(doc(db, "instructions", toAdd[i].id))

    await updateDoc(companyref, {
      instructions: arrayUnion({
        ...instruction.data(),
        id: instruction.id,
      }),
    })
  }
}

//private methods

const getAuthenticatedUser = async () => {
  if (!localStorage.getItem("authUser")) return null
  const uid = JSON.parse(localStorage.getItem("authUser"))?.id
  const user = (await getDoc(doc(db, "users", uid))).data()
  localStorage.setItem("authUser", JSON.stringify({ ...user, uid }))
  return { ...user, uid }
}

const displayMessage = (setMessage, message) => {
  setMessage(message)

  setTimeout(() => {
    setMessage()
  }, 5000)
}

const generatePassword = () => {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  var passwordLength = 8
  var password = ""

  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length)
    password += chars.substring(randomNumber, randomNumber + 1)
  }

  return password
}
