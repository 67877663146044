import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useParams, withRouter } from "react-router-dom"
import { isEmpty, map } from "lodash"
import * as moment from "moment"
import {
  Badge,
  Col,
  Container,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import * as Yup from "yup"
import { FieldArray, useFormik } from "formik"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  assignInstruction,
  getDepartments,
  getInstructionsAdmin,
} from "helpers/firebase"
import Loading from "components/Common/Loading"

//redux

const InstructionsAdmin = () => {
  const { id } = useParams()
  const [instruction, setInstruction] = useState()
  const [dueDate, setDueDate] = useState()
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(false)
  const [instructions, setInstructions] = useState([])
  const [departments, setDepartments] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState([])

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: instruction ?? {},
    validationSchema: Yup.object({}),
    onSubmit: async values => {
      toggle()

      setLoading(true)
      let date = dueDate
      if (!date) date = new Date(new Date().getFullYear(), 11, 31)
      else date = new Date(date)

      var idx = instructions.findIndex(i => i.id == instruction.id)
      instructions[idx].departments = selectedDepartments
      instructions[idx].dueDate = date

      await assignInstruction(instructions, id)
      setInstructions(await getInstructionsAdmin(id))
      setLoading(false)
    },
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
      setInstruction(null)
    } else {
      setModal(true)
    }
  }

  const handleInstructionClick = arg => {
    setInstruction(arg)
    setSelectedDepartments(arg.departments ?? [])
    if (arg.dueDate)
      setDueDate(arg.dueDate.toDate().toLocaleDateString("en-CA"))
    toggle()
  }

  useEffect(async () => {
    setInstructions(await getInstructionsAdmin(id))
    setDepartments(await getDepartments(id))
    setLoading(false)
  }, [])

  const handleValidDate = date => {
    if (date) return moment(date.toDate()).format("DD.MM.YYYY")
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Unterweisungen Admin | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Unterweisungen" breadcrumbItem="Admin" />
          <Loading isLoading={loading}>
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    <Table className="instruction-list-table table-nowrap align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Unterweisung</th>
                          <th scope="col">Datum</th>
                          <th scope="col">Abteilungen</th>
                          <th scope="col">Aktion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(instructions, (instruction, index) => (
                          <tr
                            key={index}
                            style={{ background: "white", padding: "20px" }}
                          >
                            <td>
                              <h5 className="text-truncate font-size-14">
                                {instruction.name}
                              </h5>
                            </td>
                            <td> {handleValidDate(instruction.dueDate)}</td>

                            <td>
                              <p className="m-0">
                                {instruction.departments?.map((dep, index) => {
                                  return (
                                    <span
                                      className="badge badge-soft-primary font-size-11 m-1"
                                      key={index}
                                    >
                                      {dep}
                                    </span>
                                  )
                                })}
                              </p>
                            </td>
                            <td>
                              <div
                                onClick={() =>
                                  handleInstructionClick(instruction)
                                }
                              >
                                <i className="mdi mdi-pencil font-size-16 text-success " />{" "}
                              </div>
                            </td>
                          </tr>
                        ))}
                        {(!instructions || instructions.length == 0) && (
                          <tr>
                            <td>
                              <p className="mt-3">
                                Derzeit keine Unterweisungen verfügbar!
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        Unterweisung bearbeiten
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Row form>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Datum</Label>
                                <Input
                                  name="dueDate"
                                  type="date"
                                  onChange={e => setDueDate(e.target.value)}
                                  defaultValue={dueDate}
                                />
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Abteilungen
                                </Label>
                                <br />
                                {departments?.map((dep, index) => {
                                  return (
                                    <div
                                      className="form-check mb-2 ms-3"
                                      key={index}
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"cboxDep" + index}
                                        checked={selectedDepartments.includes(
                                          dep.name
                                        )}
                                        onChange={e => {
                                          if (e.target.checked)
                                            setSelectedDepartments([
                                              ...selectedDepartments,
                                              dep.name,
                                            ])
                                          else
                                            setSelectedDepartments(
                                              selectedDepartments.filter(
                                                d => d != dep.name
                                              )
                                            )
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={"cboxDep" + index}
                                      >
                                        {dep.name}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Speichern
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
          </Loading>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(InstructionsAdmin)
