import React from "react"
import MetaTags from "react-meta-tags"
import { Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CertificatesTable from "./CertificatesTable"

const Index = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Meine Zertifikate | Sicher & Unterwiesen</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Zertifikate" breadcrumbItem="Meine Zertifikate" />
          <Row>
            <CertificatesTable />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
