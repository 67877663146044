import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import DeleteModal from "components/Common/DeleteModal"
import Loading from "components/Common/Loading"
import {
  addDepartment,
  deleteDepartment,
  getDepartments,
  getUsers,
  getUsersFromDepartment,
  sendReminders,
} from "helpers/firebase"
import { Link, useParams } from "react-router-dom"
import UsersTable from "./UsersTable"
import "./datatables.scss"

const DepartmentsTable = props => {
  const { id } = useParams()
  const [department, setDepartment] = useState()
  const [initDepartment, setInitDepartment] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingUsers, setLoadingUsers] = useState(true)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [departments, setDepartments] = useState([])
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])

  const columns = [
    {
      dataField: "name",
      text: "Bezeichnung",
      sort: true,
    },
    {
      dataField: "numOfPersons",
      text: "Anzahl Personen",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Aktionen",
      style: { width: "10%" },
      formatter: (cellContent, department) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleDepartmentClick(department)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(department)}
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "reminder",
      isDummyField: true,
      editable: false,
      text: "Erinnerung",
      style: { width: "10%" },
      formatter: (cellContent, department) => (
        <div>
          <Button
            color="primary"
            className="font-16 btn-block btn btn-primary"
            onClick={() => {
              sendReminder(department)
            }}
          >
            <i className="bx bx-mail-send font-size-16 align-middle me-2"></i>{" "}
            Erinnerung senden
          </Button>
        </div>
      ),
    },
  ]

  useEffect(async () => {
    setDepartments(await getDepartments(id))
    setLoading(false)
  }, [])

  useEffect(async () => {
    const tmp = await getUsers(id)
    setUsers(tmp)
    setLoadingUsers(false)
  }, [])

  const toggle = () => {
    setModal(!modal)
  }

  const handleAddDepartmentClick = () => {
    setDepartment({
      name: "",
    })

    setIsEdit(false)

    toggle()
  }

  const sendReminder = async department => {
    setLoading(true)
    await sendReminders(department.name, id)
    setSuccess("Erinnerungen erfolgreich gesendet!")
    setTimeout(() => {
      setSuccess()
    }, 3000)
    setLoading(false)
  }

  const handleSubmit = async () => {
    if (!selectedUsers || selectedUsers.length == 0)
      return displayMessage("Bitte mindestens 1 Person auswählen")

    toggle()
    setLoading(true)
    await addDepartment(
      department.name,
      users,
      selectedUsers,
      initDepartment,
      id
    )
    setDepartments(await getDepartments(id))
    setDepartment()
    setSelectedUsers([])
    setLoading(false)
    setError()
  }

  const handleDepartmentClick = async arg => {
    const department = arg
    setLoading(true)

    setSelectedUsers(await getUsersFromDepartment(department.name, id))

    setDepartment({
      name: department.name,
    })

    setInitDepartment(department.name)

    setIsEdit(true)
    setLoading(false)

    toggle()
  }

  const onClickDelete = departments => {
    setDepartment(departments)
    setDeleteModal(true)
  }

  const handleDeleteDepartment = async () => {
    setLoading(true)
    setDeleteModal(false)
    await deleteDepartment(department.name, users, id)
    setDepartments(await getDepartments(id))
    setLoading(false)
  }

  function displayMessage(message) {
    setError(message)

    setTimeout(() => {
      setError()
    }, 3000)
  }

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: departments?.length, // replace later with size(customers),
    custom: true,
  }

  // const { SearchBar } = Search

  return (
    <Col className="col-12">
      {success && (
        <Alert color="success" style={{ marginTop: "13px" }}>
          {success}
        </Alert>
      )}
      <Card>
        <CardBody>
          <Loading isLoading={loading}>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="name"
              columns={columns}
              data={departments}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="name"
                  columns={columns}
                  data={departments}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <Search.SearchBar
                                {...toolkitProps.searchProps}
                                placeholder="Suche"
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col md="8">
                          <div
                            style={{ marginLeft: "auto", width: "fit-content" }}
                          >
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                              onClick={handleAddDepartmentClick}
                            >
                              <i className="bx bx-add-to-queue font-size-16 align-middle me-2"></i>{" "}
                              hinzufügen
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </Loading>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Abteilung bearbeiten" : "Abteilung hinzufügen"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
              return false
            }}
          >
            <Row form>
              <Col xs={12}>
                {error && (
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {error}
                  </Alert>
                )}

                <div className="mb-3">
                  <Label className="form-label">Bezeichnung</Label>
                  <Input
                    name="departnemtname"
                    type="text"
                    onChange={e =>
                      setDepartment({ ...department, name: e.target.value })
                    }
                    value={department?.name || ""}
                    required
                  />
                </div>
                <UsersTable
                  users={users}
                  setUsers={setUsers}
                  department={department}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  loading={loadingUsers}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Speichern
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDepartment}
        onCloseClick={() => setDeleteModal(false)}
      />
    </Col>
  )
}

export default DepartmentsTable
