import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const CardInfos = props => {
  const now = new Date()
  const chartData = []
  const doneInstructionsCount = props.users.reduce(
    (count, current) => count + (current.certifications?.length ?? 0),
    0
  )
  const openInstructionsCount = props.users.reduce(
    (count, current) => count + current.instructions.length,
    0
  )

  const doneInstructions = props.users.reduce(
    (arr, current) => arr.concat(current.certifications ?? []),
    []
  )

  for (let i = 0; i < 12; i++) {
    chartData[i] = doneInstructions.filter(
      instruction => instruction.completionDate.toDate().getMonth() === i
    ).length
  }

  const series = [
    {
      name: "Abgeschlossen",
      data: chartData,
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <Col xl={8}>
      <Row>
        <Col lg={4}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Gesamt</p>
                  <h5 className="mb-0">
                    {doneInstructionsCount + openInstructionsCount}
                  </h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bxs-book-bookmark"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Abgeschlossen</p>
                  <h5 className="mb-0">{doneInstructionsCount}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bx-comment-check"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Offen</p>
                  <h5 className="mb-0">{openInstructionsCount}</h5>
                </div>

                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="bx bx-time"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            <h5 className="card-title me-2">Unterweisungen</h5>
          </div>

          <Row className="text-center">
            <Col lg={4}>
              <div className="mt-4">
                <p className="text-muted mb-1">
                  Mitarbeiter <br />
                  (aktiv & gelöscht)
                </p>
                <h5>
                  {props.users.length +
                    (props.company.deletedUsers?.filter(
                      u =>
                        u.deletedAt.toDate().getYear() === new Date().getYear()
                    ).length ?? 0)}
                </h5>
              </div>
            </Col>

            <Col lg={4}>
              <div className="mt-4">
                <p className="text-muted mb-1">Unterw. dieser Monat</p>
                <h5>
                  {
                    doneInstructions.filter(
                      f =>
                        f.completionDate.toDate().getMonth() ===
                          now.getMonth() &&
                        f.completionDate.toDate().getYear() === now.getYear()
                    ).length
                  }
                </h5>
              </div>
            </Col>

            <Col lg={4}>
              <div className="mt-4">
                <p className="text-muted mb-1">Unterw. dieses Jahr</p>
                <h5>
                  {
                    doneInstructions.filter(
                      f => f.completionDate.toDate().getYear() === now.getYear()
                    ).length
                  }{" "}
                </h5>
              </div>
            </Col>
          </Row>

          <hr className="mb-4" />
          <div className="apex-charts" id="area-chart" dir="ltr">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

CardInfos.propTypes = {
  cardreport: PropTypes.any,
  users: PropTypes.any,
  company: PropTypes.any,
}

export default CardInfos
