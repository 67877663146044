import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Label,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap"
import classnames from "classnames"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import Reviews from "./Reviews"
import deviceImg from "assets/images/devices/product-img.jpg"
import { useFormik } from "formik"

const DeviceDetails = props => {
  //meta title
  document.title = "Geräte | Sicher & Unterwiesen"

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte Email eingeben"),
    }),
    onSubmit: async () => {
      toggle()
    },
  })

  const [device, setDevice] = useState({
    specification: [
      {
        type: "Artikelnummer",
        value: "FG1839j89",
        color: "muted",
      },
      {
        type: "Hersteller",
        value: "Fred Shiller",
        color: "muted",
      },
      {
        type: "Ort",
        value: "Klagenfurt",
        color: "muted",
      },
      {
        type: "Position",
        value: "Flur 02 Gang 3",
        color: "muted",
      },
      {
        type: "Nächste Wartung",
        value: "14.09.2022",
        color: "danger",
      },
      {
        type: "Letzte Wartung",
        value: "27.01.2022",
        color: "muted",
      },
    ],
    comments: [
      {
        id: 1,
        img: "avatar2",
        name: "Brian",
        description:
          "If several languages coalesce, the grammar of the resulting language.",
        date: "5 hrs ago",
      },
    ],
  })

  const [activeTab, setActiveTab] = useState("1")

  const [modal, setModal] = useState(false)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Geräte" breadcrumbItem="Details" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="6">
                      <div className="product-detai-imgs">
                        <Row>
                          <Col md="2" xs="3">
                            <Nav className="flex-column" pills>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleTab("1")
                                  }}
                                >
                                  <img
                                    src={deviceImg}
                                    alt=""
                                    onClick={() => {}}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Col>
                          <Col md={{ size: 7, offset: 1 }} xs="9">
                            <TabContent activeTab={activeTab}>
                              <TabPane tabId="1">
                                <div>
                                  <img
                                    src={deviceImg}
                                    alt=""
                                    id="expandedImg1"
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </TabPane>
                              <TabPane tabId="2">
                                <div>
                                  <img
                                    src={deviceImg}
                                    id="expandedImg2"
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </TabPane>
                              <TabPane tabId="3">
                                <div>
                                  <img
                                    src={deviceImg}
                                    id="expandedImg3"
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </TabPane>
                              <TabPane tabId="4">
                                <div>
                                  <img
                                    src={deviceImg}
                                    id="expandedImg4"
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                  />
                                </div>
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xl="6">
                      <div className="mt-4 mt-xl-3">
                        <Link to="#" className="text-primary"></Link>
                        <h4 className="mt-1 mb-3">PA-1 Defibrillator </h4>

                        {!!device.isOffer && (
                          <h6 className="text-success text-uppercase"></h6>
                        )}
                        <h5 className="mb-4">Klagenfurt, Flur 02 T02 12</h5>
                        <p className="text-muted mb-4">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industrys standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>

                        <div className="mt-5">
                          <h5 className="mb-3">Spezifikationen :</h5>

                          <div className="table-responsive">
                            <Table className="table mb-0 table-bordered">
                              <tbody>
                                {device.specification &&
                                  device.specification.map(
                                    (specification, i) => (
                                      <tr key={i}>
                                        <th
                                          scope="row"
                                          style={{ width: "30%" }}
                                          className={"text-capitalize"}
                                        >
                                          {specification.type}
                                        </th>
                                        <td
                                          className={
                                            "text-" + specification.color
                                          }
                                        >
                                          {specification.value}
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </Table>
                          </div>

                          <div className="text-center mt-3">
                            <Button
                              type="button"
                              color="primary"
                              className="btn  mt-2 me-1 ms-1"
                              onClick={() =>
                                window.open(
                                  "https://lms.sicheruu.at/fred_pa1.pdf"
                                )
                              }
                            >
                              <i className="bx bxs-file-doc me-2" />{" "}
                              Benutzeranleitung
                            </Button>
                            <Button
                              type="button"
                              color="success"
                              className="ms-1 me-1 btn mt-2"
                              onClick={() => toggle()}
                            >
                              <i className="bx bx-cog me-2" />
                              Wartung eintragen
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Reviews comments={[...device.comments]} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Wartung
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row form>
              <Col xs={12}>
                <div className="mb-3">
                  <Label className="form-label">Datum der Wartung</Label>
                  <Input
                    name="date"
                    type="date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.date || ""}
                    invalid={
                      validation.touched.date && validation.errors.date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.date && validation.errors.date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.date}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    Wer hat die Wartung durchgeführt?
                  </Label>
                  <Input
                    name="name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Notiz</Label>
                  <Input
                    name="note"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.note || ""}
                    invalid={
                      validation.touched.note && validation.errors.note
                        ? true
                        : false
                    }
                  />
                  {validation.touched.note && validation.errors.note ? (
                    <FormFeedback type="invalid">
                      {validation.errors.note}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    onClick={toggle}
                    className="btn btn-success save-user"
                  >
                    Speichern
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

DeviceDetails.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default DeviceDetails
