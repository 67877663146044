import Loading from "components/Common/Loading"
import { getInstruction } from "helpers/firebase"
import moment from "moment"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { Container, Card, CardBody, Col, Row, Button, Alert } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Questions from "./Questions"

const InstructionDetails = () => {
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [instruction, setInstruction] = useState()
  const [duration, setDuration] = useState(5)
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [ended, setEnded] = useState(false)
  const [currentTime, setCurrentTime] = useState(false)
  const { id } = useParams()

  useEffect(async () => {
    setInstruction(await getInstruction(id))
    setLoading(false)
    setDuration(
      Math.ceil((document.querySelector("video")?.duration ?? 300) / 60)
    )
  }, [id])

  const onTimeUpdate = e => {
    if (!e.target.seeking) setCurrentTime(e.target.currentTime)
  }

  const onSeeking = e => {
    var delta = e.target.currentTime - currentTime
    if (Math.abs(delta) > 0.01) {
      e.target.currentTime = currentTime
    }
  }

  const onEnded = _ => {
    setCurrentTime(0)
    setEnded(true)
  }

  const handleValidDate = date => {
    if (date) return moment(date.toDate()).format("DD.MM.YYYY")
    return ""
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Unterweisung | Sicher & Unterwiesen</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Unterweisungen"
            breadcrumbItem={instruction?.name}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Loading isLoading={loading}>
                    <div className="pt-3">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          <div>
                            <div className="text-center">
                              <div className="mb-4">
                                <Link
                                  to="#"
                                  className="badge bg-light font-size-12"
                                >
                                  <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>
                                  Büro
                                </Link>
                              </div>
                              <h4 className="mb-4">{instruction?.name}</h4>
                            </div>

                            <div className="text-center">
                              <Row>
                                <Col>
                                  <div className="mt-4 mt-sm-0">
                                    <p className="text-muted mb-2">Bis</p>
                                    <h5
                                      className={
                                        "font-size-15 " +
                                          instruction?.dueDate?.toDate() <
                                        new Date()
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      {handleValidDate(instruction?.dueDate)}
                                    </h5>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <hr />
                            {error && error ? (
                              <Alert
                                color="danger"
                                style={{ marginTop: "13px" }}
                              >
                                {error}
                              </Alert>
                            ) : null}
                            {videoPlayed ? (
                              <>
                                {success ? (
                                  <>
                                    <div className="my-5 p-3">
                                      <div className="text-center mb-4">
                                        <div className="avatar-md mx-auto mb-4">
                                          <div className="avatar-title bg-light  rounded-circle text-success h1">
                                            <i className="bx bx-check-double"></i>
                                          </div>
                                        </div>

                                        <div className="row justify-content-center">
                                          <div className="col-xl-10">
                                            <h4 className="text-primary">
                                              Gratulation !
                                            </h4>
                                            <p className="text-muted font-size-14 mb-4">
                                              Sie haben die Unterweisung
                                              erfolgreich abgeschlossen. <br />
                                              Sie können sich Ihr Zertifikat
                                              unter &quot;Meine
                                              Zertifikate&quot; herunterladen.
                                            </p>

                                            <Link to="/zertifikate">
                                              <Button
                                                className="mx-auto d-block"
                                                color="primary"
                                              >
                                                Meine Zertifikate
                                                <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2"></i>
                                              </Button>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <Questions
                                    instruction={instruction}
                                    setSuccess={setSuccess}
                                    setError={setError}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                <div className="my-5">
                                  <video
                                    id="video"
                                    width="600"
                                    height="400"
                                    controls
                                    onTimeUpdate={onTimeUpdate}
                                    onSeeking={onSeeking}
                                    onEnded={onEnded}
                                    className="mx-auto d-block"
                                    style={{
                                      maxWidth: "100%",
                                      height: "fit-content",
                                    }}
                                  >
                                    <source
                                      src={instruction?.videoURL}
                                      type="video/mp4"
                                    />
                                    Ihr Browser unterstüzt Videos nicht.
                                  </video>
                                </div>

                                <div className="mt-4">
                                  {ended && (
                                    <Button
                                      className="ms-auto d-block"
                                      color="primary"
                                      onClick={() => setVideoPlayed(true)}
                                    >
                                      Weiter
                                      <i className="bx bx-right-arrow-alt font-size-16 align-middle ms-2"></i>
                                    </Button>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Loading>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InstructionDetails
