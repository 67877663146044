import { useFormik } from "formik"
import * as moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import * as Yup from "yup"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../components/Common/DeleteModal"

import Loading from "components/Common/Loading"
import {
  addCompany,
  addInstructionToCompany,
  deleteCompany,
  getAllInstructions,
  getCompanies,
  updateCompany,
} from "helpers/firebase"
import { Link } from "react-router-dom"

const Companies = props => {
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [instructions, setInstructions] = useState([])

  useEffect(async () => {
    setCompanies(await getCompanies())
    setInstructions(await getAllInstructions())

    setLoading(false)
  }, [])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [company, setCompany] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (company && company.name) || "",
      representative: (company && company.representative) || "",
      phoneNr: (company && company.phoneNr) || "",
      email: (company && company.email) || "",
      street: (company && company.street) || "",
      zipCode: (company && company.zipCode) || "",
      city: (company && company.city) || "",
      invoiceDate: company && company.invoiceDate,
      licenses: (company && company.licenses) || 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Bitte Firmennamen eingeben"),
      representative: Yup.string(),
      phoneNr: Yup.string(),
      email: Yup.string(),
      street: Yup.string(),
      zipCode: Yup.string(),
      city: Yup.string(),
      invoiceDate: Yup.date().required("Bitte Rechnungsdatum angeben"),
    }),
    onSubmit: async values => {
      setLoading(true)
      if (isEdit) {
        values.id = company ? company.id : 0
        await updateCompany(values)
        validation.resetForm()
      } else {
        console.log(
          values["invoiceDate"] == "" ? new Date() : values["invoiceDate"]
        )
        const newCompany = {
          name: values["name"],
          representative: values["representative"],
          phoneNr: values["phoneNr"],
          email: values["email"],
          street: values["street"],
          zipCode: values["zipCode"],
          city: values["city"],
          invoiceDate:
            values["invoiceDate"] == ""
              ? new Date().toLocaleDateString()
              : values["invoiceDate"],
          licenses: values["licenses"] ?? 0,
        }

        await addCompany(newCompany)
        validation.resetForm()
      }
      toggle()
      setCompanies(await getCompanies())
      setLoading(false)
    },
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: companies.length, // replace later with size(orders),
    custom: true,
  }

  const CompaniesColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "name",
      text: "Firmenname",
      sort: true,
      formatter: (cellContent, row) => (
        <h5 className="font-size-14 mb-1">
          <Link to={"/betriebe/" + row.id} className="text-dark">
            {row.name}
          </Link>
        </h5>
      ),
    },
    {
      dataField: "representative",
      text: "Beauftragter",
      sort: true,
    },
    {
      text: "Kontakt",
      dataField: "contact",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.phoneNr}</p>
          <p className="mb-0">{row.email}</p>
        </>
      ),
    },
    {
      dataField: "street",
      text: "Adresse",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.street ?? ""} ${row.zipCode ?? ""} ${
            row.city ?? ""
          }`}</p>
        </>
      ),
    },
    {
      text: "Aktive Ma./Lizenzen",
      dataField: "countLicenses",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p
            className={
              (row.activeLicenses > (row.licenses ?? 0)
                ? "text-danger"
                : "text-success") + " text-center mb-0"
            }
          >
            {row.activeLicenses} / {row.licenses ?? 0}
          </p>
        </>
      ),
    },
    {
      dataField: "invoiceDate",
      text: "Abrechnung am",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.invoiceDate),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#" onClick={() => handleCompanyClick(row)}>
              <i className="fas fa-pencil-alt text-success me-1" /> Bearbeiten
            </DropdownItem>
            <DropdownItem href={"/betriebe/" + row.id}>
              <i className=" bx bx-user  text-muted me-1" /> Mitarbeiter
            </DropdownItem>
            <DropdownItem onClick={() => handleInstructionsClick(row)}>
              <i className="fas fa-cog text-muted me-1" /> Unterweisungen
            </DropdownItem>
            <DropdownItem href={"/abteilungen/" + row.id}>
              <i className=" bx bx-building text-muted me-1" /> Abteilungen
            </DropdownItem>
            <DropdownItem href={"/unterweisungen-admin/" + row.id}>
              <i className=" bx bx-right-arrow-alt text-muted me-1" /> Zuweisung
            </DropdownItem>
            <DropdownItem href="#" onClick={() => onClickDelete(row)}>
              <i className="fas fa-trash-alt text-danger me-1" /> Löschen
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ]
  const handleSubmit = async e => {
    e.preventDefault()
    toggleInstruction()
    setLoading(true)
    const checkedInstructions = instructions.filter(i => i.isChecked)

    await addInstructionToCompany(company.id, checkedInstructions)
    window.location.reload(false)
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCompany(null)
    } else {
      setModal(true)
    }
  }

  const toggleInstruction = () => {
    if (instructionModal) {
      setInstructionModal(false)
      setCompany(null)
    } else {
      setInstructionModal(true)
    }
  }

  const handleCompanyClick = company => {
    setCompany(company)

    setIsEdit(true)
    toggle()
  }

  const handleCheckboxChange = (index, value) => {
    let items = [...instructions]
    let item = { ...items[index] }
    item.isChecked = value
    items[index] = item
    setInstructions(items)
  }

  const handleInstructionsClick = company => {
    setInstructions(
      instructions.map(i => {
        return {
          ...i,
          isChecked: company.instructions?.find(element => element.id === i.id)
            ? true
            : false,
        }
      })
    )
    setCompany(company)
    toggleInstruction()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete company
  const [deleteModal, setDeleteModal] = useState(false)
  const [instructionModal, setInstructionModal] = useState(false)

  const onClickDelete = company => {
    setCompany(company)
    setDeleteModal(true)
  }

  const handleDeleteCompany = async () => {
    if (company.id) {
      await deleteCompany(company.id)
      onPaginationPageChange(1)
      setDeleteModal(false)
      window.location.reload(false)
    }
  }

  // const { SearchBar } = Search

  const handleCompanyClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD.MM.YYYY")
    return date1
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCompany}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Betriebe | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Betriebe" breadcrumbItem="Betriebe" />
          <Row>
            <Loading isLoading={loading}>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={CompaniesColumns}
                      data={companies}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={companies || []}
                          columns={CompaniesColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Search.SearchBar
                                        {...toolkitProps.searchProps}
                                        placeholder="Suche"
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleCompanyClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      Neuer Betrieb
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      keyField="id"
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      ref={node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? "Betrieb bearbeiten" : "Betrieb hinzufügen"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Row form>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Beauftragter
                                </Label>
                                <Input
                                  name="representative"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.representative || ""}
                                  invalid={
                                    validation.touched.representative &&
                                    validation.errors.representative
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.representative &&
                                validation.errors.representative ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.representative}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Telefonnummer
                                </Label>
                                <Input
                                  name="phoneNr"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.phoneNr || ""}
                                  invalid={
                                    validation.touched.phoneNr &&
                                    validation.errors.phoneNr
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.phoneNr &&
                                validation.errors.phoneNr ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.phoneNr}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Straße und Hausnummer
                                </Label>
                                <Input
                                  name="street"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.street || ""}
                                  invalid={
                                    validation.touched.street &&
                                    validation.errors.street
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.street &&
                                validation.errors.street ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.street}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">PLZ</Label>
                                <Input
                                  name="zipCode"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.zipCode || ""}
                                  invalid={
                                    validation.touched.zipCode &&
                                    validation.errors.zipCode
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.zipCode &&
                                validation.errors.zipCode ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.zipCode}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Stadt</Label>
                                <Input
                                  name="city"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.city || ""}
                                  invalid={
                                    validation.touched.city &&
                                    validation.errors.city
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.city &&
                                validation.errors.city ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.city}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Rechnungsdatum
                                </Label>
                                <Input
                                  name="invoiceDate"
                                  type="date"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.invoiceDate || ""}
                                  invalid={
                                    validation.touched.invoiceDate &&
                                    validation.errors.invoiceDate
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.invoiceDate &&
                                validation.errors.invoiceDate ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.invoiceDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Gekaufte Ma.
                                </Label>
                                <Input
                                  name="licenses"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.licenses || ""}
                                  invalid={
                                    validation.touched.licenses &&
                                    validation.errors.licenses
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.licenses &&
                                validation.errors.licenses ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.licenses}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-company"
                                >
                                  Speichern
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                    <Modal isOpen={instructionModal} toggle={toggleInstruction}>
                      <ModalHeader toggle={toggleInstruction} tag="h4">
                        Unterweisungen
                      </ModalHeader>
                      <ModalBody>
                        <Form onSubmit={handleSubmit}>
                          <Row form>
                            <Col className="col-12">
                              {instructions.map((instruction, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="form-check form-check-primary mb-3"
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={"cbox" + index + index}
                                      onChange={e => {
                                        handleCheckboxChange(
                                          index,
                                          e.target.checked
                                        )
                                      }}
                                      checked={
                                        instructions[index].name.startsWith(
                                          "Allgemein Teil"
                                        )
                                          ? true
                                          : instructions[index].isChecked
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"cbox" + index + index}
                                    >
                                      {instruction.name}
                                    </label>
                                  </div>
                                )
                              })}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-company"
                                >
                                  Speichern
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Loading>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Companies
