import moment from "moment"
import React, { Component } from "react"
import { Link } from "react-router-dom"

const certificationsColumns = () => [
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    text: "Datum",
    dataField: "completionDate",
    sort: true,
    formatter: (cellContent, instruction) => (
      <p className="p-0 m-1 text-muted">
        {moment(new Date(instruction.dueDate?.toDate())).format("DD.MMM.Y")}
      </p>
    ),
  },
]

export default certificationsColumns
