import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Card, CardBody, Col, Row } from "reactstrap"

//Import Breadcrumb

import Loading from "components/Common/Loading"
import { isEmpty } from "lodash"
import { usersColumns } from "./UsersColumns"

const UsersTable = props => {
  const { users, selectedUsers, setSelectedUsers } = props
  const [isEdit, setIsEdit] = useState(false)
  // const { SearchBar } = Search
  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: users.length, // replace later with size(users),
    custom: true,
  }
  const defaultSorted = [
    {
      dataField: "lastname", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      selectedUsers.push(row.id)
      setSelectedUsers(selectedUsers)
      return true
    } else {
      setSelectedUsers(selectedUsers.filter(u => u != row.id))
      return true
    }
  }

  const handleOnSelectAll = isSelect => {
    if (isSelect) {
      setSelectedUsers(users.map(u => u.id))
      return users
    }

    setSelectedUsers([])
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedUsers,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  }

  useEffect(() => {
    setIsEdit(false)
  }, [users])

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setIsEdit(false)
    }
  }, [users])

  var node = useRef()

  const keyField = "id"

  return (
    <Card>
      <CardBody>
        <Loading isLoading={props.loading}>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField={keyField}
            columns={usersColumns}
            data={users}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField={keyField}
                  data={users}
                  columns={usersColumns}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl="12">
                          <div className="search-box mx-auto mb-2 d-inline-block">
                            <div className="position-relative">
                              <Search.SearchBar
                                placeholder="Suche"
                                {...toolkitProps.searchProps}
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
        </Loading>
      </CardBody>
    </Card>
  )
}

UsersTable.propTypes = {
  department: PropTypes.object,
  users: PropTypes.array,
  loading: PropTypes.bool,
  setUsers: PropTypes.func,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
}

export default UsersTable
