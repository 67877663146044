import moment from "moment"
import React from "react"

export const certificationsColumns = [
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "completionDate",
    text: "Datum",
    sort: true,
    formatter: (cellContent, row) => <>{handleValidDate(row.completionDate)}</>,
  },
]

const handleValidDate = date => {
  if (date) return moment(date.toDate()).format("DD.MM.YYYY")
  return ""
}
