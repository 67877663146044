import React, { useState } from "react"
import PropTypes from "prop-types"
import { Input, Button } from "reactstrap"

//action

const CommentBox = ({
  value,
  onChange,
  comment,
  onCancelReply,
  isCommentAdd,
  onCancelComment,
  onAdd,
}) => {
  return (
    <div className="w-100">
      <div className="w-100 py-3">
        <Input
          type="textarea"
          name="text"
          id="exampleText"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </div>
      <div className="w-100">
        {isCommentAdd ? (
          <div className="text-end">
            <Button color="primary" onClick={onAdd}>
              Speichern
            </Button>
            <Button
              color="secondary"
              className="float-end ms-2"
              onClick={() => {
                onCancelComment()
              }}
            >
              Abbrechen
            </Button>
          </div>
        ) : (
          <div className="text-end">
            <Button color="primary" onClick={onAdd}>
              Add
            </Button>
            <Button
              color="secondary"
              className="float-end ms-2"
              onClick={() => {
                onCancelReply(comment.commentId)
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

CommentBox.propTypes = {
  onCancelReply: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  comment: PropTypes.any,
  onCancelReply: PropTypes.func,
  isCommentAdd: PropTypes.any,
  onCancelComment: PropTypes.func,
  onAdd: PropTypes.func,
}

const CommentItem = ({ comment, onCancelReply, index, onAddReply }) => {
  const [replyText, setReplyText] = useState("")

  const onChangeReplyText = value => {
    setReplyText(value)
  }

  const onAddReplyToComment = () => {
    onAddReply(comment.commentId, replyText)
    setReplyText("")
  }

  return (
    <div
      className={
        comment.index + 1 === 1
          ? "d-flex py-3 border-bottom"
          : "d-flex py-3 border-bottom"
      }
      key={"__media__" + index}
    >
      <div className="avatar-xs me-3" style={{ minWidth: "2rem" }}>
        <span className="avatar-title bg-primary bg-soft text-primary rounded-circle font-size-16">
          U
        </span>
      </div>
      <div className="flex-grow-1">
        <h5 className="mt-0 mb-1 font-size-15">Max Mustermann</h5>
        <p className="text-muted">
          Das ist ein Beispiel Kommentar. Hier können Nutzer Kommentare
          hinzufügen, um Wartungen oder Ausfälle zu dokumentieren.
        </p>

        <div className="text-muted font-size-12">
          <i className="far fa-calendar-alt text-primary me-1" />
          03.07.2022
        </div>

        {/* add comment box */}
        {comment.showAddComment && (
          <CommentBox
            comment={comment}
            onCancelReply={onCancelReply}
            isCommentAdd={false}
            value={replyText}
            onChange={onChangeReplyText}
            onAdd={onAddReplyToComment}
          />
        )}
      </div>
    </div>
  )
}

CommentItem.propTypes = {
  comment: PropTypes.any,
  onLikeUnlikeComment: PropTypes.func,
  onClickReply: PropTypes.func,
  onCancelReply: PropTypes.func,
  index: PropTypes.any,
  onLikeUnlikeReply: PropTypes.func,
  onAddReply: PropTypes.func,
}

const Reviews = ({ comments, onAddReply, onAddComment }) => {
  const [commentText, setCommentText] = useState("")
  const onChangeCommentText = value => {
    setCommentText(value)
  }

  const onLikeUnlikeComment = () => {
    // dispatch(onLikeComment(commentId, productId))
  }

  const onLikeUnlikeReply = () => {
    // dispatch(onLikeReply(commentId, productId, replyId))
  }

  const [isCommentAdd, setIsCommentAdd] = useState(false)
  const onClickAddComment = () => {
    setIsCommentAdd(true)
  }
  const onCancelComment = () => {
    setIsCommentAdd(false)
  }

  const onAddProductComment = () => {
    onAddComment(commentText)
    setCommentText("")
  }

  return (
    <div className="mt-5">
      <h5 className="mb-4">Notizen :</h5>
      {(comments || []).map((comment, k) => {
        return (
          <CommentItem
            comment={comment}
            onLikeUnlikeComment={onLikeUnlikeComment}
            onLikeUnlikeReply={onLikeUnlikeReply}
            index={k}
            key={k}
            onAddReply={onAddReply}
          />
        )
      })}

      {isCommentAdd ? (
        <CommentBox
          isCommentAdd={true}
          onCancelComment={onCancelComment}
          value={commentText}
          onChange={onChangeCommentText}
          onAdd={onAddProductComment}
        />
      ) : (
        <div className="w-100 text-end pt-3">
          <Button color="primary" onClick={onClickAddComment}>
            Kommentar hinzufügen
          </Button>
        </div>
      )}
    </div>
  )
}

Reviews.propTypes = {
  comments: PropTypes.array,
  reply: PropTypes.any,
  productId: PropTypes.any,
  onClickReply: PropTypes.func,
  onCancelReply: PropTypes.func,
  onAddReply: PropTypes.func,
  onAddComment: PropTypes.func,
}

export default Reviews
