import moment from "moment"
import React, { Component } from "react"
import { Link } from "react-router-dom"

const instructionsColumns = () => [
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    text: "Datum",
    dataField: "dueDate",
    sort: true,
    formatter: (cellContent, instruction) => (
      <p
        className={
          "p-0 m-1 " +
          (instruction.dueDate?.toDate() < new Date()
            ? "text-danger"
            : "text-muted")
        }
      >
        {moment(new Date(instruction.dueDate?.toDate())).format("DD.MMM.Y")}
      </p>
    ),
  },
]

export default instructionsColumns
