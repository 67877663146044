import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import MetaTags from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import Loading from "components/Common/Loading"
import { getAllInstructions } from "helpers/firebase"

const InstructionsSuperAdmin = props => {
  const [instructions, setInstructions] = useState([])
  const [instructionsList, setInstructionsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(false)

  // const { SearchBar } = Search
  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: instructions.length, // replace later with size(users),
    custom: true,
  }
  const defaultSorted = [
    {
      dataField: "name", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const instructionsColumns = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => (
        <h5 className="font-size-14 mb-1">
          <Link
            to={"/unterweisungen-superadmin/" + row.id}
            className="text-dark"
          >
            {row.name}
          </Link>
        </h5>
      ),
    },
    {
      dataField: "companiesCount",
      text: "Anzahl Betriebe",
      sort: true,
    },
    {
      dataField: "questionsCount",
      text: "Anzahl Fragen",
      sort: true,
      headerAlign: "center",
      formatter: (cellContent, instruction) => {
        return (
          <p className="mb-0 text-center">{instruction.questions.length}</p>
        )
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Aktionen",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, instruction) => (
        <div className="d-flex gap-3">
          <Link
            to={"/unterweisungen-superadmin/" + instruction.id}
            className="text-success"
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </Link>
        </div>
      ),
    },
  ]

  useEffect(async () => {
    setInstructions(await getAllInstructions())
    setLoading(false)
  }, [])

  const toggle = () => {
    setModal(!modal)
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const handleDeleteUser = () => {
    // dispatch(onDeleteUser(instruction))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setInstructionsList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Unterweisungen | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Loading isLoading={loading}>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Unterweisungen"
              breadcrumbItem="Alle Unterweisungen"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField={keyField}
                      columns={instructionsColumns}
                      data={instructions}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <ToolkitProvider
                            keyField={keyField}
                            data={instructions}
                            columns={instructionsColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <Search.SearchBar
                                          placeholder="Suche"
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Link to="/unterweisung-neu">
                                        <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                          onClick={handleUserClicks}
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Unterweisung hinzufügen
                                        </Button>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        selectRow={selectRow}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )
                      }}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Loading>
      </div>
    </React.Fragment>
  )
}

export default withRouter(InstructionsSuperAdmin)
