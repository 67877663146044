import React from "react"

import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Overview
import DashboardAdmin from "../pages/DashboardAdmin"

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//  // Inner Authentication

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Instructions
import InstructionsAdmin from "pages/InstructionsAdmin/instructionsAdmin"
import Instruction from "pages/InstructionsSuperAdmin/Instruction"
import InstructionsSuperAdmin from "pages/InstructionsSuperAdmin/instructionsSuperAdmin"
import InstructionDetails from "../pages/Instructions/InstructionDetails"
import InstructionsList from "../pages/Instructions/InstructionsList/index"

//Certificates
import Certificates from "../pages/Certificates/index"

//Certificates
import Companies from "../pages/Companies/index"

//Departments
import Departments from "../pages/Departments/index"

//Pages
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesMaintenance from "../pages/Utility/pages-maintenance"

//Contacts
import DeviceDetails from "pages/Devices/DeviceDetails"
import EmployeeDetails from "pages/Employees/EmployeeDetails"
import EmployeesAdmin from "pages/EmployeesAdmin"
import EmployeeAdminDetails from "pages/EmployeesAdmin/EmployeeAdminDetails"
import InvalidLicense from "pages/Utility/invalid-license"
import Employees from "../pages/Employees"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/account", component: UserProfile },

  //Unterweisungen
  { path: "/unterweisungen", component: InstructionsList },
  { path: "/unterweisungen/:id", component: InstructionDetails },
  { path: "/unterweisung-neu", component: Instruction },
  { path: "/unterweisungen-superadmin/:id", component: Instruction },

  //Unterweisungen Admin
  { path: "/dashboard-admin", component: DashboardAdmin, isAdmin: true },
  {
    path: "/unterweisungen-admin",
    component: InstructionsAdmin,
    isAdmin: true,
  },
  {
    path: "/unterweisungen-admin/:id",
    component: InstructionsAdmin,
    isSuperAdmin: true,
  },
  {
    path: "/unterweisungen-superadmin",
    component: InstructionsSuperAdmin,
    isSuperAdmin: true,
  },

  //Certificates
  { path: "/zertifikate", component: Certificates },

  //companies
  { path: "/betriebe", component: Companies, isSuperAdmin: true },
  { path: "/betriebe/:id", component: EmployeesAdmin, isSuperAdmin: true },
  {
    path: "/betriebe/:companyID/:userID",
    component: EmployeeAdminDetails,
    isSuperAdmin: true,
  },

  //Departments
  { path: "/abteilungen", component: Departments, isAdmin: true },
  { path: "/abteilungen/:id", component: Departments, isSuperAdmin: true },

  // Employees
  { path: "/mitarbeiter", component: Employees, isAdmin: true },
  { path: "/mitarbeiter/:id", component: EmployeeDetails, isAdmin: true },

  //Devices

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/invalidLicense", component: InvalidLicense },

  { path: "/devices/1234", component: DeviceDetails },
]

export { authProtectedRoutes, publicRoutes }
