import React, { useEffect, useState } from "react"
import { Button, Form } from "reactstrap"
import PropTypes from "prop-types"
import { completeInstruction } from "helpers/firebase"

const Questions = ({ instruction, setSuccess, setError }) => {
  const [questions, setQuestions] = useState([])
  const [userAnswers, setUserAnswers] = useState([])

  useEffect(() => {
    setQuestions(getRandomQuestions())
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    setError()
    let answerIDX = 0
    let success = true

    questions.forEach(question => {
      question.answers
        .filter(a => a.text)
        .forEach(answer => {
          if (userAnswers[answerIDX] != answer.isCorrect) success = false
          answerIDX++
        })
    })

    if (success) {
      await completeInstruction(instruction)
      setSuccess(true)
    } else {
      window.scrollTo(0, 0)

      setError(
        "Sie haben die Fragen leider falsch beantwortet. Bitte versuchen Sie es erneut!"
      )
      setTimeout(() => {
        setError()
      }, 4000)
    }
  }

  function getRandomQuestions() {
    const array = instruction.questions
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    const questionList = array.slice(0, 2)
    const answersList = []
    let length = 0
    questionList.forEach(
      question => (length += question.answers?.filter(a => a.text).length)
    )
    for (let i = 0; i < length; i++) answersList.push(false)

    setUserAnswers(answersList)

    return questionList
  }

  return (
    <div className="my-5 p-3">
      <h4 className="card-title">Fragen</h4>
      <p className="card-title-desc mb-5">
        Bitte beantworten Sie folgende Fragen um das Zertifikat für diese
        Unterweisung zu erhalten:
      </p>
      <Form onSubmit={handleSubmit}>
        {questions.map((question, index) => {
          return (
            <div className="my-5" key={index}>
              <h5 className="font-size-14 mb-4">
                {index + 1 + ". "}&emsp;
                {question.text}
              </h5>

              {question.answers.map((answer, index2) => {
                return (
                  answer.text && (
                    <div
                      key={index2}
                      className="form-check form-check-primary mb-3"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"cbox" + index + index2}
                        onChange={e => {
                          let op = Object.values(
                            questions.slice(0, index)
                          ).reduce(
                            (out, inp) =>
                              out + inp.answers.filter(a => a.text).length,
                            0
                          )
                          userAnswers[op + index2] = e.target.checked
                          setUserAnswers(userAnswers)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"cbox" + index + index2}
                      >
                        {answer.text}
                      </label>
                    </div>
                  )
                )
              })}
            </div>
          )
        })}

        <Button
          color="success"
          type="submit"
          //   onClick={() => setSuccess(true)}
          className="mx-auto d-block"
        >
          <i className="bx bx-check-double font-size-16 align-middle me-2"></i>
          Abschicken
        </Button>
      </Form>
    </div>
  )
}

Questions.propTypes = {
  instruction: PropTypes.any,
  setSuccess: PropTypes.any,
  setError: PropTypes.any,
}
export default Questions
