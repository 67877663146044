import { useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Link, useParams, withRouter } from "react-router-dom"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { isEmpty, map, size } from "lodash"

//redux
import Loading from "components/Common/Loading"
import {
  createUser,
  deleteUser,
  getDepartments,
  getUsers,
  updateUser,
} from "helpers/firebase"
import { MetaTags } from "react-meta-tags"

const Employees = () => {
  const [employee, setEmployee] = useState({})
  const [departments, setDepartments] = useState()
  const { id } = useParams()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: employee ?? {},
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte Email eingeben"),
    }),
    onSubmit: async values => {
      values.isAdmin = isAdmin
      try {
        setLoading(true)
        if (!values.departments) values.departments = []
        if (values.departments?.includes("Keine Abteilung"))
          values.departments = []
        if (isEdit) {
          values.id = employee.id
          await updateUser(values, setError)
          setUsers(await getUsers(id))
          validation.resetForm()
          setIsEdit(false)
          setLoading(false)
          toggle()
        } else {
          await createUser(values, setError, id)
          validation.resetForm()
          setUsers(await getUsers(id))
          toggle()
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
        setModal(false)
      }
    },
  })

  const [modal, setModal] = useState(false)
  const [error, setError] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [users, setUsers] = useState([])
  // const { SearchBar } = Search
  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: users.length, // replace later with size(users),
    custom: true,
  }
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const employeeListColumns = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "img",
      text: "#",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.firstname.charAt(0)}
            </span>
          </div>
        </>
      ),
    },
    {
      text: "Name",
      dataField: "firstname",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link
              to={"/betriebe/" + id + "/" + user.email}
              className="text-dark"
            >
              {user.firstname + " " + user.lastname}
            </Link>
          </h5>
          <p className="text-muted mb-0">
            {map(
              user.departments,
              (tag, index) =>
                index < 2 && (
                  <Link
                    to="#"
                    className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                    key={"_skill_" + user.id + index}
                  >
                    {tag}
                  </Link>
                )
            )}
            {size(user.departments) > 2 && (
              <Link
                to="#"
                className="badge badge-soft-primary font-size-11 m-1 ms-0 me-2"
                key={"_skill_" + user.id}
              >
                + {size(user.departments) - 1} weitere
              </Link>
            )}
          </p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cellContent, user) => (
        <a href={"mailto:" + user.email} className="avatar-xs">
          {user.email}
        </a>
      ),
    },
    {
      dataField: "instructionsCount",
      text: "Abgeschl. Unterw.",
      sort: true,
      headerAlign: "center",
      formatter: (cellContent, user) => (
        <p className="mb-0 text-center">{user.certifications?.length ?? 0}</p>
      ),
    },
    {
      dataField: "openInstructions",
      text: "Offene Unterw.",
      sort: true,
      headerAlign: "center",
      formatter: (cellContent, user) => (
        <p className="mb-0 text-center">{user.instructions?.length ?? 0}</p>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Aktionen",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(user)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(user)}
            ></i>
          </Link>
        </div>
      ),
    },
  ]
  useEffect(() => {
    // setEmployee(users)
    setIsEdit(false)
  }, [users])

  useEffect(async () => {
    setUsers(await getUsers(id))
    setLoading(false)
    setIsEdit(false)
  }, [])

  useEffect(async () => {
    setDepartments(await getDepartments(id))
  }, [])

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setEmployee(users)
      setIsEdit(false)
    }
  }, [users])

  //für den järhlichen Report der abgeschlossenen Unterweisungen
  // const generateReport = allUsers => {
  //   let results = ""
  //   allUsers?.forEach(user => {
  //     if (user.certifications)
  //       results +=
  //         user.empNr +
  //         "," +
  //         user.firstname +
  //         "," +
  //         user.lastname +
  //         "," +
  //         user.email +
  //         "," +
  //         user.certifications?.length +
  //         "," +
  //         user.certifications?.map(c => c.name).join(";") +
  //         "\n"
  //   })
  //   console.log(results)
  // }

  const toggle = () => {
    setIsEdit(false)
    setModal(!modal)
    setEmployee({})
  }

  const handleUserClick = emp => {
    setIsAdmin(emp.isAdmin ?? false)
    setEmployee(emp)
    setIsEdit(true)
    setModal(true)
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = user => {
    setEmployee(user)
    setDeleteModal(true)
  }

  const handleDeleteUser = async () => {
    try {
      setLoading(true)
      setDeleteModal(false)
      await deleteUser(employee.id, setError)
      setUsers(users.filter(u => u.id != employee.id))
      onPaginationPageChange(1)
      setEmployee()
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Mitarbeiter | Sicher & Unterwiesen LMS</title>
        </MetaTags>
        <Container fluid>
          {error ? <Alert color="danger">{error}</Alert> : null}

          <Breadcrumbs title="Mitarbeiter" breadcrumbItem="Meine Mitarbeiter" />
          <Row>
            <Col lg="12">
              <Loading isLoading={loading}>
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField={keyField}
                      columns={employeeListColumns}
                      data={users}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <ToolkitProvider
                            keyField={keyField}
                            data={users}
                            columns={employeeListColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <Search.SearchBar
                                          placeholder="Suche"
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={toggle}
                                      >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Mitarbeiter hinzufügen
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        selectRow={selectRow}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={node}
                                      />

                                      <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle} tag="h4">
                                          {!!isEdit
                                            ? "Mitarbeiter bearbeiten"
                                            : "Mitarbeiter hinzufügen"}
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form
                                            onSubmit={e => {
                                              e.preventDefault()
                                              validation.handleSubmit()
                                              return false
                                            }}
                                          >
                                            <Row form>
                                              <Col xs={12}>
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Vorname
                                                  </Label>
                                                  <Input
                                                    name="firstname"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .firstname || ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .firstname &&
                                                      validation.errors
                                                        .firstname
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .firstname &&
                                                  validation.errors
                                                    .firstname ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .firstname
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Nachname
                                                  </Label>
                                                  <Input
                                                    name="lastname"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .lastname || ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .lastname &&
                                                      validation.errors.lastname
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched
                                                    .lastname &&
                                                  validation.errors.lastname ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .lastname
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>

                                                <div className="mb-3 d-flex">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                    style={{
                                                      marginRight: "0.5rem",
                                                    }}
                                                    onChange={() =>
                                                      setIsAdmin(!isAdmin)
                                                    }
                                                    checked={isAdmin}
                                                  />
                                                  <label
                                                    className="form-check-label d-block"
                                                    htmlFor="customControlInline"
                                                  >
                                                    Beauftragter (Ja/Nein){" "}
                                                  </label>
                                                </div>

                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Email
                                                  </Label>
                                                  <Input
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values.email ||
                                                      ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .email &&
                                                      validation.errors.email
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched.email &&
                                                  validation.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                      {validation.errors.email}
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>

                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Personalnummer
                                                  </Label>
                                                  <Input
                                                    name="empNr"
                                                    label="Personalnummer"
                                                    type="text"
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values.empNr ||
                                                      ""
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .empNr &&
                                                      validation.errors.empNr
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  {validation.touched.empNr &&
                                                  validation.errors.empNr ? (
                                                    <FormFeedback type="invalid">
                                                      {validation.errors.empNr}
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>

                                                <div className="mb-3">
                                                  <Label className="form-label">
                                                    Abteilung
                                                  </Label>
                                                  <Input
                                                    type="select"
                                                    name="departments"
                                                    className="form-select"
                                                    multiple={true}
                                                    onChange={
                                                      validation.handleChange
                                                    }
                                                    onBlur={
                                                      validation.handleBlur
                                                    }
                                                    value={
                                                      validation.values
                                                        .departments || []
                                                    }
                                                    invalid={
                                                      validation.touched
                                                        .departments &&
                                                      validation.errors
                                                        .departments
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    <option>
                                                      Keine Abteilung
                                                    </option>
                                                    {departments &&
                                                      departments?.map(
                                                        department => {
                                                          return (
                                                            <option
                                                              key={
                                                                department.name
                                                              }
                                                            >
                                                              {department.name}
                                                            </option>
                                                          )
                                                        }
                                                      )}

                                                    {/* <option>Büro</option>
                                                    <option>Lager</option>
                                                    <option>Stapler</option>
                                                    <option>Lasten</option>
                                                    <option>Lacke</option> */}
                                                  </Input>
                                                  {validation.touched
                                                    .departments &&
                                                  validation.errors
                                                    .departments ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .departments
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <div className="text-end">
                                                  <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                  >
                                                    Speichern
                                                  </button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )
                      }}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Loading>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Employees)
