import React from "react"
import PropTypes from "prop-types"

function Loading(props) {
  return props.isLoading == true ? (
    <div id="preloader">
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
          <div className="chase-dot"></div>
        </div>
      </div>
    </div>
  ) : (
    <>{props.children}</>
  )
}

Loading.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.any.isRequired,
}

export default Loading
