import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, Col, Row, TabContent, TabPane } from "reactstrap"

import Loading from "components/Common/Loading"
import { getInstructionsForUser } from "helpers/firebase"
import moment from "moment"

const InstructionsList = () => {
  const [instructions, setInstructions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    setInstructions(await getInstructionsForUser())
    setLoading(false)
  }, [])

  const handleValidDate = date => {
    if (date) return moment(date.toDate()).format("DD.MM.YYYY")
    return ""
  }

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Loading isLoading={loading}>
          <Card>
            <TabContent className="p-4" activeTab="1">
              <TabPane tabId="1">
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <div>
                        <Row className="align-items-center">
                          <Col xs={12}>
                            <div>
                              <h4 className="mb-0">Offene Unterweisungen</h4>
                            </div>
                          </Col>
                        </Row>

                        <hr className="mb-5" />
                        {(!instructions || instructions.length == 0) && (
                          <p className="text-center">
                            Derzeit alle Unterweisungen abgeschlossen!
                          </p>
                        )}
                        {instructions?.map((element, index) => {
                          return (
                            <div key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <h5>
                                    <Link
                                      to={"/unterweisungen/" + element.id}
                                      className="text-dark"
                                    >
                                      {element.name}
                                    </Link>
                                  </h5>

                                  <p
                                    className={
                                      element.dueDate?.toDate() < new Date()
                                        ? "text-danger"
                                        : "text-muted"
                                    }
                                  >
                                    bis: {handleValidDate(element.dueDate)}
                                  </p>
                                </div>
                                <div style={{ marginRight: "5%" }}>
                                  <Link
                                    to={"/unterweisungen/" + element.id}
                                    className="text-primary"
                                  >
                                    Jetzt starten
                                    <i className="mdi mdi-arrow-right"></i>
                                  </Link>
                                </div>
                              </div>
                              <hr className="my-5" />
                            </div>
                          )
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          </Card>
        </Loading>
      </Col>
    </React.Fragment>
  )
}

export default InstructionsList
