export const isSICHERUU = () => {
  const currentURL = window.location.href

  // String to check for in the URL
  const stringToCheckFor = "lms.sicheruu.at"

  // Check if the URL contains the desired string
  if (currentURL.includes(stringToCheckFor)) return true
  return false
}
