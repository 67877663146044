import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const authUser =
    localStorage.getItem("authUser") ??
    '{"isAdmin": false, "isSuperAdmin": false }'
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            <li>
              <Link to="/unterweisungen" className="">
                <i className="bx bxs-detail" />
                <span>Meine Unterweisungen</span>
              </Link>
            </li>

            <li>
              <Link to="/zertifikate" className="">
                <i className="bx bxs-certification" />
                <span>Meine Zertifikate</span>
              </Link>
            </li>

            {JSON.parse(authUser).isAdmin && (
              <>
                <li className="menu-title">Admin</li>
                <li>
                  <Link to="/dashboard-admin" className="">
                    <i className="bx bx-home-circle"></i>
                    <span>Übersicht</span>
                  </Link>
                </li>
                <li>
                  <Link to="/abteilungen">
                    <i className="bx bxs-building"></i>
                    <span>Abteilungen</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mitarbeiter">
                    <i className="bx bxs-user-detail"></i>
                    <span>Meine Mitarbeiter</span>
                  </Link>
                </li>
                <li>
                  <Link to="/unterweisungen-admin" className=" ">
                    <i className="bx bxs-detail"></i>
                    <span>Unterweisungen</span>
                  </Link>
                </li>{" "}
              </>
            )}

            {JSON.parse(authUser)?.isSuperAdmin && (
              <>
                <li className="menu-title">Superadmin</li>
                <li>
                  <Link to="/unterweisungen-superadmin" className="">
                    <i className="bx bx-detail"></i>
                    <span>Unterweisungen</span>
                  </Link>
                </li>
                <li>
                  <Link to="/betriebe">
                    <i className="bx bxs-building"></i>
                    <span>Betriebe</span>
                  </Link>
                </li>{" "}
              </>
            )}

            <li className="menu-title">Einstellungen</li>

            <li>
              <Link to="/account">
                <i className="bx bx-user"></i>
                <span>Account</span>
              </Link>
            </li>

            <li>
              <Link to="/logout">
                <i className="bx bxs-log-out"></i>
                <span>Ausloggen</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
