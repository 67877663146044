import React from "react"
import PropTypes from "prop-types"
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment"

const Warnings = ({ users }) => {
  const warnings = []
  const warningDate = new Date().getDate() + 3

  users.forEach(user => {
    const instructions = user.instructions.filter(i => i.dueDate > warningDate)

    if (instructions.length > 0) warnings.push(user)
  })

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Warnungen</CardTitle>

        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {warnings.map((element, index) => (
                <tr key={index}>
                  <td>
                    <h5 className="font-size-14 m-0">
                      <Link to="" className="text-dark">
                        {element.firstname} {element.lastname}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <h6 className="text-danger m-0">
                      {moment(
                        element.instructions
                          .sort(
                            (a, b) => a.dueDate.toDate() - b.dueDate.toDate()
                          )[0]
                          .dueDate.toDate()
                      ).format("DD.MM.Y")}
                    </h6>
                  </td>
                  {/* <td>
                    <Button color="primary" className="btn btn-primary m-0">
                      <i className="bx bx-time"></i>
                    </Button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

Warnings.propTypes = {
  users: PropTypes.array,
}

export default Warnings
